<template>
  <div class="col-md-12">
    <div class="card">
      <a-spin :spinning="spinning" :delay="delayTime">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <b>Delivery Challan Number: </b> {{ delivery_challan.delivery_challan_number }}<br>
              <b>Delivery Challan Type: </b> {{ delivery_challan.challan_type === 1 ? 'Internal' : 'External' }}<br>
              <b>Delivery Challan Status: </b>
              <a-tag color="#f50" v-if="delivery_challan.delivery_challan_status.includes('SENDER_SEND') && !delivery_challan.delivery_challan_status.includes('RECEIVER_RECEIVED')">Sender Send</a-tag>
              <a-tag color="#87d068" v-if="delivery_challan.delivery_challan_status.includes('RECEIVER_PENDING') && delivery_challan.delivery_challan_status.includes('RECEIVER_RECEIVED')">Receiver Received</a-tag><br>
              <a-tag color="#87d068" v-if="delivery_challan.delivery_challan_status.includes('RECEIVER_PENDING') && delivery_challan.delivery_challan_status.includes('RECEIVER_REJECTED')">Receiver Rejected</a-tag><br>
            </div>
            <div class="col-sm-6">
              <b>Delivery Challan Diapatch at : </b> {{ customDate(delivery_challan.dispatch_date) }}<br>
              <b>Delivery Challan Received at: </b> <span v-if="delivery_challan.delivery_challan_statuses.filter(e => e.status === 'RECEIVER_RECEIVED').length > 0">{{ customDate(delivery_challan.delivery_challan_statuses.find(e => e.status === 'RECEIVER_RECEIVED').created_at) }}</span>
            </div>
          </div>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'delivery_challan_header_detail',
  props: ['delivery_challan', 'spinning', 'delayTime'],
  data() {
    return {}
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
  },
}
</script>

<style scoped>

</style>
